import React from "react";
import "./Costs.css";

const Costs = () => {
    return (
        <section id="costs">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                        <h2>Kostenvergleich von Osmoseanlage und gekauftem Wasser.</h2>

                        <h3>Basis für die Berechnung:</h3>
                        <ul>
                            <li><strong>Kosten für Leitungswasser:</strong> 0,20 Euro pro Liter</li>
                            <li><strong>Kosten für gekauftes Wasser:</strong> 0,50 Euro pro Liter</li>
                            <li><strong>Wasserverbrauch pro Person pro Tag:</strong> 2,5 Liter</li>
                            <li><strong>Anzahl der Personen:</strong> 4 Personen Haushalt</li>
                            <li><strong>Jährliche Wartungskosten der Osmoseanlage:</strong> 100,- Euro</li>
                        </ul>

                        <h3>Berechnungen</h3>
                        <div className="hint">
                            <p><strong>Hinweis:</strong> Diese Berechnung ist allgemein und dient als Beispiel. Die tatsächlichen Einsparungen und Kosten können je nach individuellen Umständen variieren. Es wird empfohlen, eine individuelle Berechnung basierend auf dem eigenen Wasserverbrauch und den spezifischen Kosten durchzuführen.</p>
                        </div>

                        <h4>1. Wasserverbrauch und -kosten pro Jahr:</h4>
                        <p>
                            <strong>Täglicher Wasserverbrauch für die Familie:</strong><br />
                            Täglicher Verbrauch = 4 Personen × 2,5 Liter/Person = 10 Liter
                        </p>

                        <p>
                            <strong>Jährlicher Wasserverbrauch:</strong><br />
                            Jährlicher Verbrauch = 10 Liter/Tag × 365 Tage = 3.650 Liter
                        </p>

                        <h4>2. Kosten für Wasser aus dem Laden:</h4>
                        <p>
                            Jährliche Kosten = 3.650 Liter × 0,50 Euro/Liter = 1.825,- Euro
                        </p>

                        <h4>3. Kosten für Leitungswasser:</h4>
                        <p>
                            Jährliche Kosten = 3.650 Liter × 0,20 Euro/Liter = 730,- Euro
                        </p>

                        <h4>4. Gesamtkosten für die Osmoseanlage über 5 Jahre:</h4>
                        <p>
                            <strong>Anschaffungskosten:</strong> 1.500 Euro<br />
                            <strong>Jährliche Wartungskosten:</strong> 100 Euro<br />
                            <strong>Jährliche Kosten für Leitungswasser:</strong> 730 Euro<br />
                            <strong>Gesamtkosten über 5 Jahre:</strong> 1.500 + (100 × 5) + (730 × 5) = 1.500 + 500 + 3.650 = <u>5.650 Euro</u>
                        </p>

                        <h3 className="mt-5">Tabellenansicht</h3>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Osmoseanlage</th>
                                        <th>Gekauftes Wasser</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Anschaffungskosten (einmalig)</td>
                                        <td>1.500,- Euro</td>
                                        <td>0,- Euro</td>
                                    </tr>
                                    <tr>
                                        <td>Jährliche Wartungskosten</td>
                                        <td>100,- Euro</td>
                                        <td>0,- Euro</td>
                                    </tr>
                                    <tr>
                                        <td>Jährliche Kosten für Wasser</td>
                                        <td>730,- Euro</td>
                                        <td>1.825,- Euro</td>
                                    </tr>
                                    <tr>
                                        <td>Gesamtkosten nach 5 Jahren</td>
                                        <td>5.650,- Euro</td>
                                        <td>9.125,- Euro</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className="border-bottom-0 text-success text-center fw-bold">Einsparungen nach 5 Jahren<br />3.475,- Euro</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Costs;