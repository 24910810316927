import React from 'react';
import './Product.css';
import Image from '../../images/wasserfilter.webp';

const Product = () => {
    return (
        <div className="container">
            <section id="main-content" className="text-center py-5">
                <div className="row">
                    <div className="offset-lg-2">
                        <div className="col-lg-8">
                            <h2>Die Osmoseanlage – Ihr Schlüssel zu gesundem Trinkwasser</h2>
                            <p>Hochwertige Osmoseanlagen setzen den Standard, wenn es um die Bereitstellung von reinem, gesundem Trinkwasser geht. Sie sind ideal für Haushalte, Büros und Unternehmen, die besonderen Wert auf exzellente Wasserqualität legen und dabei keine Kompromisse eingehen möchten. Mit fortschrittlicher Filtrationstechnologie und zahlreichen innovativen Funktionen garantieren diese Anlagen ein unvergleichbares Niveau an Reinheit und Sicherheit.</p>
                        </div>
                    </div>
                </div>
                <img src={Image} alt="Osmoseanlage - Reines Wasser für Ihr Zuhause" className="img-fluid" /><div className="offset-lg-2">
            </div>
            </section>
        </div>
    )
}

export default Product;