import React, { useEffect, useState } from 'react';
import './DarkMode.css';

const ThemeToggle = () => {
    const [isLightMode, setIsLightMode] = useState(false);

    useEffect(() => {
        const userPrefersLight = localStorage.getItem('theme') === 'light';
        setIsLightMode(userPrefersLight);

        if (userPrefersLight) {
            document.body.classList.add('light-mode');
        } else {
            document.body.classList.remove('light-mode');
        }
    }, []);

    const handleToggle = () => {
        document.body.classList.toggle('light-mode');
        const newTheme = document.body.classList.contains('light-mode') ? 'light' : 'dark';
        localStorage.setItem('theme', newTheme);
        setIsLightMode(newTheme === 'light');
    };

    return (
        <button id="theme-toggle" onClick={handleToggle}>
            {isLightMode ? <i className="bi bi-sun-fill"></i> : <i className="bi bi-moon-fill"></i>}
        </button>
    );
};

export default ThemeToggle;
