import React from 'react';
import './Features.css';

const Features = () => {
    return (
        <section id="grid-content" className="bg-light">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2  mb-5 text-center">
                        <div className="col-lg-8">
                            <h2>Warum Osmoseanlagen eine gute Wahl sind</h2>
                            <p>Osmoseanlagen bieten nicht nur reines Wasser, sondern auch Sicherheit, Komfort und Nachhaltigkeit. Sie filtern Schadstoffe zuverlässig heraus, bereichern das Wasser mit gesunden Mineralien und arbeiten gleichzeitig effizient, um Wasser und Ressourcen zu sparen.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 d-flex align-items-center">
                        <i className="bi bi-filter"></i>
                        <div>
                            <h3>Mehrstufiges Filtersystem</h3>
                            <p>Osmoseanlagen verwenden bis zu 5 Filterstufen, um Ihr Leitungswasser von allen
                                Verunreinigungen zu befreien. Diese Kombination entfernt 99,99 % aller Verunreinigungen,
                                wie Schwermetalle (Blei, Quecksilber), Chemikalien (Pestizide, Chlor) und
                                Mikroorganismen (Bakterien, Viren).</p>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-5 d-flex align-items-center">
                        <i className="bi bi-arrow-repeat"></i>
                        <div>
                            <h3>Automatisches Spülsystem für maximale Lebensdauer</h3>
                            <p>Um die Leistung der Membran zu optimieren und ihre Lebensdauer zu verlängern, spült das
                                System die Filter automatisch in regelmäßigen Abständen. Dies verhindert Verstopfungen
                                und
                                sorgt dafür, dass das System stets mit voller Effizienz arbeitet.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 d-flex align-items-center">
                        <i className="bi bi-droplet-half"></i>
                        <div>
                            <h3>Integrierte Remineralisierung</h3>
                            <p>Obwohl das System sämtliche Verunreinigungen entfernt, wird das Wasser nach der Filtration
                                mit essentiellen Mineralien wie Magnesium und Kalzium angereichert. Dies sorgt für einen
                                frischen Geschmack und sichert die gesundheitlichen Vorteile von mineralisiertem Wasser.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 d-flex align-items-center">
                        <i className="bi bi-water"></i>
                        <div>
                            <h3>Energieeffizientes Design mit niedrigem Abwasseranteil</h3>
                            <p>Dank eines optimierten Rückgewinnungsmechanismus wird der Abwasseranteil meist auf nur 1:1
                                reduziert oder sogar weniger. Solche Systeme arbeiten ressourcenschonend und sparen im
                                Vergleich zu traditionellen Osmoseanlagen Wasser, was es auch für umweltbewusste Kunden
                                attraktiv macht.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 d-flex align-items-center">
                        <i className="bi bi-piggy-bank"></i>
                        <div>
                            <h3>Kosteneffizienz</h3>
                            <p>Langfristig können Osmoseanlagen Geld sparen, da sie die Notwendigkeit für den Kauf von teurem Flaschenwasser reduzieren. Nach der Anfangsinvestition und den minimalen Wartungskosten sind die laufenden Kosten für gefiltertes Wasser sehr gering.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 d-flex align-items-center">
                        <i className="bi bi-recycle"></i>
                        <div>
                            <h3>Umweltfreundlich</h3>
                            <p>Die Nutzung einer Osmoseanlage reduziert den Verbrauch von Einweg-Plastikflaschen, die die Umwelt belasten. Weniger Plastikabfall bedeutet weniger Umweltverschmutzung und einen kleineren ökologischen Fußabdruck.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 d-flex align-items-center">
                        <i className="bi bi-heart-pulse"></i>
                        <div>
                            <h3>Gesundheitliche Vorteile</h3>
                            <p>Reines Wasser ist wichtig für die Gesundheit. Osmoseanlagen entfernen schädliche Chemikalien und Verunreinigungen, die langfristig gesundheitliche Probleme verursachen können. Dadurch wird das Risiko von Krankheiten, die durch verunreinigtes Wasser verursacht werden, reduziert.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 d-flex align-items-center">
                        <i className="bi bi-cup-straw"></i>
                        <div>
                            <h3>Verbesserung des Geschmacks</h3>
                            <p>Das durch Osmoseanlagen gefilterte Wasser schmeckt oft besser, da unerwünschte Stoffe, die den Geschmack beeinträchtigen könnten, entfernt werden. Dies führt zu einem erfrischenderen und reineren Geschmack.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;