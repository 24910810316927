import React  from "react";
import './App.css';
import './index.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import Cookie from './features/CookieConsent/CookieConsent';
import Header from './components/Header/Header';
import Product from './components/Product/Product';
import Features from './components/Features/Features';
import Compare from './components/Compare/Compare';
import SplitColumns from './components/SplitColumns/SplitColumns';
import Costs from './components/Costs/Costs';
import Faq from './components/Faq/Faq';
import Voucher from './components/Voucher/Voucher';
import Footer from './components/Footer/Footer';

function App() {
  return (
      <div className="content-wrapper">
          <Cookie />
          <a href="#main-content" className="skip-link">Zum Inhalt springen</a>
          <div className="App">
              <Header />
              <Product />
              <Features />
              <Compare />
              <SplitColumns />
              <Costs />
              <Faq />
              <Voucher />
              <Footer />
          </div>
      </div>
  );
}

export default App;