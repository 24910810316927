import React from 'react';
import './Compare.css';

const Compare = () => {
    return (
        <section id="compare-content" className="py-5">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                        <h2>Osmosefilter vs. herkömmliche Wasserfilter</h2>
                        <h3>Welcher ist der richtige für Sie?</h3>
                        <p>Beim Vergleich zwischen einem Osmosefilter und herkömmlichen Wasserfiltern stellt sich
                            schnell
                            heraus, dass die Osmoseanlage in Sachen Effizienz und Reinheit klar die Nase vorn hat.
                            Während
                            einfache Filter meist nur grobe Partikel oder chemische Stoffe wie Chlor entfernen, arbeitet
                            die
                            Umkehrosmose auf molekularer Ebene. Sie filtert bis zu 99 % aller Schadstoffe wie Bakterien,
                            Viren,
                            Pestizide, Schwermetalle und Mikroplastik aus dem Wasser.</p>

                        <div className="row">
                            <div className="row">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <b>Herkömmliche Filter:</b>
                                        <ul>
                                            <li>Entfernen nur grobe Verunreinigungen</li>
                                            <li>Geringere Filterleistung für Chemikalien und Schadstoffe</li>
                                            <li>Häufige Wartung und regelmäßige Filterwechsel erforderlich</li>
                                            <li>Behalten oft Mineralien, die den Geschmack beeinträchtigen können</li>
                                            <li>Weniger effektiv bei der Entfernung von Bakterien und Viren</li>
                                            <li>Wasserqualität kann variieren, je nach Wasserquelle</li>
                                            <li>Meist keine Möglichkeit zur Nachmineralisierung</li>
                                            <li>Können Gerüche und Geschmäcker nicht immer effektiv entfernen</li>
                                            <li>Keine Mehrstufenreinigung verfügbar</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <b>Osmosefilter:</b>
                                        <ul>
                                            <li>Mehrstufige Reinigung für maximale Reinheit</li>
                                            <li>Entfernt auch kleinste Partikel wie Schwermetalle und Viren</li>
                                            <li>Längere Lebensdauer der Filter im Vergleich zu herkömmlichen Systemen</li>
                                            <li>Hohe Effizienz bei der Entfernung von Chemikalien und Schadstoffen</li>
                                            <li>Erzeugt Wasser von nahezu destillierter Qualität</li>
                                            <li>Reduziert Kalkablagerungen in Haushaltsgeräten</li>
                                            <li>Verbessert den Geschmack und Geruch des Wassers erheblich</li>
                                            <li>Kann mit Nachmineralisierungsfilter ausgestattet werden</li>
                                            <li>Hohe Konstanz der Wasserqualität</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <p>Wenn Ihnen die Reinheit Ihres Trinkwassers wichtig ist, führt kein Weg an einem Osmosefilter vorbei. Diese fortschrittlichen Filtersysteme bieten eine überlegene Wasserreinigungstechnologie, die selbst kleinste Verunreinigungen entfernt. Durch den Einsatz einer semipermeablen Membran werden Schadstoffe, Chemikalien und Mikroorganismen effektiv aus dem Wasser gefiltert.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Compare;