import React, { useEffect, useState } from 'react';
import DarkMode from '../../features/DarkMode/DarkMode';
import './NavBar.css';

const Navbar = () => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isNavbarFixed, setIsNavbarFixed] = useState(true);

    useEffect(() => {
        const navbar = document.getElementById('mainNav');
        const navbarHeight = navbar.offsetHeight;
        const delta = 5;

        const handleScroll = () => {
            const currentScroll = window.scrollY || document.documentElement.scrollTop;

            if (Math.abs(lastScrollTop - currentScroll) <= delta) return;

            if (currentScroll > lastScrollTop && currentScroll > navbarHeight) {
                if (isNavbarFixed) {
                    navbar.style.transform = 'translateY(-100%)';
                    navbar.style.opacity = '0';
                    setIsNavbarFixed(false);
                }
            } else {
                if (!isNavbarFixed) {
                    navbar.style.transform = 'translateY(0)';
                    navbar.style.opacity = '1';
                    setIsNavbarFixed(true);
                }
            }

            setLastScrollTop(currentScroll);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop, isNavbarFixed]);

    return (
        <div className="header">
            <header id="mainNav" className="fixed-top">
                <div className="mainNav-inner">
                    <nav className="navbar navbar-expand-lg p-0">
                        <div className="container">
                            <div className="collapse navbar-collapse justify-content-center" id="ds">
                                <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                                    <li>
                                        <a href="#main-content">Osmosefilter</a>
                                    </li>
                                    <li>
                                        <a href="#grid-content">Vorteile</a>
                                    </li>
                                    <li>
                                        <a href="#compare-content">Vergleich</a>
                                    </li>
                                    <li>
                                        <a href="#costs">Kosten</a>
                                    </li>
                                    <li>
                                        <a href="#faq">FAQ</a>
                                    </li>
                                    <li className="highlight">
                                        <a href="#sparen">10% sparen</a>
                                    </li>
                                </ul>
                                <DarkMode />
                            </div>
                            <button className="navbar-toggler border-0 shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="bi bi-list"></i>
                            </button>
                        </div>
                    </nav>
                </div>
            </header>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header justify-content-end">
                    <button type="button" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="canvasMenu">
                        <li data-bs-dismiss="offcanvas">
                            <a href="#main-content">Osmosefilter</a>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                            <a href="#grid-content">Vorteile</a>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                            <a href="#compare-content">Vergleich</a>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                            <a href="#faq">FAQ</a>
                        </li>
                        <li data-bs-dismiss="offcanvas" className="highlight">
                            <a href="#sparen">10% sparen</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
