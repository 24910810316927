import React from 'react';
import Navbar from "../Navbar/NavBar";
import './Header.css';

const Header = () => {
    return (
        <div className="header-image">
            <section>
                <div className="container">
                    <Navbar />
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <h1>Reines Wasser für Ihr Zuhause</h1>
                            <p className="header-content">
                                In einer Welt, in der die Qualität unseres Trinkwassers zunehmend in Frage gestellt wird, gewinnen Osmoseanlagen als effektive Lösung zur Reinigung von Wasser immer mehr an Bedeutung.
                            </p>
                            <p><a href="#main-content" className="btn btn-lg btn-custom">Mehr zu <b>Osmoseanlagen</b></a></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Header;