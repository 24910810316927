import React from 'react';
import './SpliColumns.css';
import RightImage from '../../images/naturschonend.webp';
import LeftImage from '../../images/gesundheit.webp';

const SplitColumns = () => {
    return (
        <section>
            <div className="bg-dark text-white row g-0 align-items-center">
                <div className="col-lg-6 p-5">
                    <h2>Vorteile für Ihre Gesundheit</h2>
                    <ul>
                        <li>Reduziert das Risiko, Schadstoffe wie Blei, Chlor und Pestizide zu sich zu nehmen.</li>
                        <li>Verbessert den Geschmack von Wasser, Kaffee, Tee und gekochten Speisen.</li>
                        <li>Unterstützt einen aktiven, gesunden Lebensstil durch konstanten Zugang zu hochwertigem Wasser.</li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <img src={LeftImage} alt="Sonnuntergang mit Menschen - Vorteile für Ihre Gesundheit" className="img-fluid" />
                </div>
            </div>
            <div className="bg-dark text-white row g-0 align-items-center">
                <div className="col-lg-6">
                    <img src={RightImage} alt="Naturbild -Vorteile für die Umwelt" className="img-fluid" />
                </div>
                <div className="col-lg-6 p-5">
                    <h2>Vorteile für die Umwelt</h2>
                    <ul>
                        <li>Vermeidet den Kauf und die Entsorgung von Einwegplastikflaschen.</li>
                        <li>Spart Energie und Ressourcen im Vergleich zur industriellen Wasseraufbereitung.</li>
                        <li>Unterstützt den nachhaltigen Umgang mit Trinkwasser durch effizientere Filtrationssysteme.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default SplitColumns;