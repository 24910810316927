import React from 'react';
import './Footer.css';

const year = new Date().getFullYear();

const Footer = () => {
    return (
            <div className="footer text-center">
                <div className="container">
                    <div className="social-media mb-5 py-5">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/nahrungsergaenzungsmitteltipps/" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/nahrungserganzungsmittel_tipps/" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div className="terms">
                        <ul>
                            <li>
                                <a href="https://www.nahrungsergaenzungsmittel-tipps.de/"
                                   target="_blank" rel="noreferrer">Blog</a>
                            </li>
                            <li>
                                <a href="https://www.nahrungsergaenzungsmittel-tipps.de/datenschutzerklaerung/"
                                   target="_blank" rel="noreferrer">Datenschutz</a>
                            </li>
                            <li>
                                <a href="https://www.nahrungsergaenzungsmittel-tipps.de/impressum/" target="_blank"
                                   rel="noreferrer">Impressum</a>
                            </li>
                        </ul>
                    </div>
                    <div className="copyright py-3">
                        {year} - Nahrungsergänzungsmittel Tipps
                    </div>
                </div>
            </div>
    )
}

export default Footer;