import React from 'react';
import './Faq.css';

const Faq = () => {
    return (
        <section id="faq" className="bg-light py-5">
            <div className="container">
                <div className="row mb-5 text-center">
                    <div className="offset-lg-2 col-lg-8">
                        <h2>FAQ</h2>
                        <p>Häufig gestellte Fragen</p>
                    </div>
                </div>
                <div className="row faq-content">
                    <div className="col-lg-6 mb-3">
                        <strong>Was ist eine Osmoseanlage und wie funktioniert sie?</strong>
                        <p>Eine Osmoseanlage nutzt das Prinzip der Umkehrosmose, um Wasser durch eine halbdurchlässige
                            Membran zu pressen. Dabei werden Verunreinigungen und Schadstoffe nahezu vollständig
                            herausgefiltert, sodass nur reines Wasser übrig bleibt.</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <strong>Sind Osmosefilter teuer in der Anschaffung?</strong>
                        <p>Die Anschaffungskosten für Osmosefilter sind in der Regel höher als bei herkömmlichen Wasserfiltern. Allerdings können die langfristigen Einsparungen bei Wasserflaschen, besserer Wasserqualität und weniger Wartungskosten diese anfänglichen Investitionen rechtfertigen.</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <strong>Kann eine Osmoseanlage an jedes Wasserleitungssystem angeschlossen werden?</strong>
                        <p>Ja, Osmoseanlagen sind so konzipiert, dass sie einfach und flexibel in jede
                            Standard-Kücheninstallation integriert werden können.</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <strong>Entfernt die Osmoseanlage auch Mineralien?</strong>
                        <p>Ja, eine Osmoseanlage filtert alle gelösten Stoffe, inklusive Mineralien. Dies sorgt für
                            extrem reines Wasser, das viele für seinen neutralen Geschmack und die Gesundheit
                            bevorzugen. Je nach Gerät können Sie remineralisierende Filter nutzen.</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <strong>Wie viel Prozent der Schadstoffe werden von Osmoseanlagen entfernt?</strong>
                        <p>Osmoseanlagen können bis zu 99,99 % aller Verunreinigungen aus dem Wasser entfernen,
                            einschließlich Schwermetalle wie Blei und Quecksilber, Chemikalien wie Chlor und Pestizide
                            sowie Mikroorganismen wie Bakterien und Viren.</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <strong>Ist das gefilterte Wasser sicher zum Trinken?</strong>
                        <p>Ja, das gefilterte Wasser ist sehr sicher zum Trinken. Osmoseanlagen entfernen schädliche Stoffe und remineralisieren das Wasser in der Regel, um essentielle Mineralien hinzuzufügen und den Geschmack zu verbessern.</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <strong>Produziert ein Osmosefilter Abwasser?</strong>
                        <p>Ja, Osmosefilter produzieren in der Regel Abwasser, da nicht alles Wasser durch die Membran gelangt. Für jeden Liter gereinigtes Wasser fallen ca. 1 Liter Abwasser an. Es gibt jedoch effizientere Modelle, indem sie den Wasserverbrauch optimieren.</p>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <strong>Wie oft müssen die Filter gewechselt werden?</strong>
                        <p>Das hängt von der Nutzung ab. Im Durchschnitt sollten die Vorfilter alle 6 Monate und die
                            Osmosemembran alle 2 Jahre gewechselt werden.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq;